var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.sort,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "sort", $$v)
                },
                expression: "listQuery.sort"
              }
            },
            _vm._l(_vm.sortOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "router-link",
            {
              staticClass: "margin-horizontal",
              attrs: { to: "/users-payments/add/" + _vm.userId }
            },
            [
              _vm.showAdd
                ? _c(
                    "el-button",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "filter-item",
                      attrs: {
                        type: "success",
                        size: "small",
                        icon: "el-icon-plus"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              width: "80",
              align: "center",
              label: _vm.$t("table.id"),
              prop: "id",
              "class-name": _vm.getSortClass("id")
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              align: "center",
              prop: "transactionReference",
              label: "Reference"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.transactionReference || scope.row.utrNumber
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              align: "center",
              prop: "status",
              label: "Status"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-tag", { attrs: { type: "success", size: "mini" } }, [
                      _vm._v(" " + _vm._s(scope.row.status) + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              align: "center",
              prop: "amount",
              label: "Amount"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("inr", { attrs: { number: scope.row.amount } })]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              align: "center",
              prop: "paymentGateway",
              label: "Method"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.paymentGateway
                      ? _c("el-tag", { attrs: { size: "mini" } }, [
                          _vm._v(" " + _vm._s(scope.row.paymentGateway) + " ")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              align: "center",
              prop: "paymentMethod",
              label: "Mode"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "Transaction Date",
              width: "150px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            scope.row.transactionDate,
                            "DD-MM-YYYY"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.createdDate"),
              width: "200px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            scope.row.createdTimestamp,
                            "DD-MM-YYYY hh:mm a"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Actions", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "Linked Transactions",
                          placement: "top"
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-tickets",
                            size: "mini"
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleDialog(scope.row.id)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Linked Transactions",
            width: "90%",
            visible: _vm.dialogVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("transaction-list", {
            key: _vm.paymentId,
            attrs: { "payment-id": _vm.paymentId }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }